import CIRCLE_DELETE_ACH_DEPOSIT from '@/graphql/mutations/CircleDeleteAchDeposit.gql';
import CIRCLE_DELETE_ACH_WITHDRAW from '@/graphql/mutations/CircleDeleteAchWithdraw.gql';
import CIRCLE_DELETE_CARD_DEPOSIT from '@/graphql/mutations/CircleDeleteCreditCard.gql';
import CIRCLE_DELETE_WIRE_DEPOSIT from '@/graphql/mutations/CircleDeleteWireDeposit.gql';
import CIRCLE_DELETE_WIRE_WITHDRAW  from '@/graphql/mutations/CircleDeleteWireWithdraw.gql';

export default [
    {
        transactionType: 'deposit',
        methodType: 'ach',
        mutation: CIRCLE_DELETE_ACH_DEPOSIT,
    },
    {
        transactionType: 'deposit',
        methodType: 'card',
        mutation: CIRCLE_DELETE_CARD_DEPOSIT,
    },
    {
        transactionType: 'deposit',
        methodType: 'wire',
        mutation: CIRCLE_DELETE_WIRE_DEPOSIT,
    },
    {
        transactionType: 'withdraw',
        methodType: 'ach',
        mutation: CIRCLE_DELETE_ACH_WITHDRAW,
    },
    {
        transactionType: 'withdraw',
        methodType: 'wire',
        mutation: CIRCLE_DELETE_WIRE_WITHDRAW,
    },
];
