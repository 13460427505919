<template>
  <div class="method-removal-confirmation">
    <header>
      <div class="headline-small mt-s16">
        {{ $t("wallet.modal.remove_methods.title") }}
      </div>

      <div
        class="method-removal-description mt-s24"
        v-html="$t('wallet.modal.remove_methods.description')"
      ></div>
    </header>

    <div class="flex flex-col items-end gap-s16 -m-s16 p-s16">
      <ButtonV2
        class="capitalize"
        size="medium"
        :label="$tc('wallet.modal.remove_methods.actions.delete')"
        testId="btn-delete"
        @onClick="removeMethod"
        wide
        version="destructive"
      />

      <ButtonV2
        :label="$tc('wallet.modal.remove_methods.actions.cancel')"
        testId="btn-cancel"
        version="secondary"
        size="medium"
        wide
        @onClick="previousModal"
      />
    </div>
  </div>
</template>

<script>
import MethodRemovalMutations from '@/enums/CircleMethodRemovalMutations.enum';
import { ButtonV2 } from '@/components/misc';
import { mapActions } from 'vuex';

export default {
  name: 'MethodRemovalConfirmation',

  mounted() {
    this.setModalInitialState();
  },

  props: {
    type: {
      type: String,
    },

    method: {
      type: Object,
      required: true,
    },
  },

  components: { ButtonV2 },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    isDeposit() {
      return this.type === 'deposit';
    },

    removalMutation() {
      const found = MethodRemovalMutations.find(
          (item) => item.transactionType === this.type && item.methodType === this.method.type
      );
      return found ? found.mutation : null;
    }
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),
    ...mapActions('wallet', ['handlePaymentMethodRemoval']),

    setModalInitialState() {
      this.setModalProps({
        hasPrevious: true,
        handlePrevious: this.previousModal,
        isClosable: false,
        type: this.type,
        method: this.method,
      });
    },

    async removeMethod() {
      if (!this.removalMutation) {
        await this.showError(new Error('remove_payment_method_error'));
        return;
      }

      await this.handlePaymentMethodRemoval({
        methodId: this.method.id,
        mutation: this.removalMutation,
      });

      await this.showSuccess(
        this.$t('wallet.modal.remove_methods.messages.removal_success')
      );

      this.previousModal();
    },

    previousModal() {
      this.showModal('PaymentInterface', {
        isDeposit: this.isDeposit,
      });
    },
  },
};
</script>

<style scoped>
.method-removal-confirmation {
  @apply mt-s16 flex flex-col justify-between;
  height: 597px;
  max-height: 100%;
  max-width: 100%;
  width: 450px;
}

.method-removal-description {
  @apply body-text-large;
}
</style>
